import { createSagas } from 'redux-box'
import { all, call, put, take } from 'redux-saga/effects'
import rsFirebase, { firebaseSecondary } from 'services/rsf'
import RTE, { rteCardsTag, rteManageUserId } from 'utils/routes'
import { push } from 'react-router-redux'
import uid from 'uid'
import Cookies from 'js-cookie'
import { LAST_APP_VISITED } from "../../utils/consts";
import 'firebase/auth';
import {
  getUserActiveApps,
  getUserId,
  getUserList,
  getUserProfile,
} from './workers'
import { updateOneCard } from "../card/workers";

export default createSagas({
  *MANAGE_USERLIST_GET({ userId, appId }){
    try {
      yield put({ type: 'MANAGE_USERS_FETCHING' })

      if(!userId){ userId = getUserId()}

      const list = yield call(getUserList, userId, appId)

      yield put({ type: 'MANAGE_USERS_SET', list })
    } catch (error) {
      yield put({ type: 'MANAGE_USERS_FETCH_ERROR', error })
    }
  },
  *MANAGE_GET_USER_INFO() {
    try {
      const userId = getUserId()
      yield put({ type: 'MANAGE_USERS_FETCHING' })
      yield call(getUserProfile, userId)
    } catch (error) {
      yield put({ type: 'MANAGE_USERS_FETCH_ERROR', error })
    }
  },
  *MANAGE_USER_DISABLE({ user, appId }) {
    try {
      yield put({ type: 'MANAGE_USERS_SAVING' })

      yield call(rsFirebase.firestore.updateDocument, `users/${user.id}`, 'disabled', !user.disabled)

      const list = yield call(getUserList, user.id, appId)

      yield put({ type: 'MANAGE_USERS_SET', list })

      yield put({ type: 'MANAGE_USERS_SAVE_SUCCESS' })
    } catch (error) {
      yield put({ type: 'MANAGE_USERS_SAVE_ERROR' })
    }
  },
  *MANAGE_USER_SAVE_REQUEST({ user, isShare }) {
    const isNew = !user.id

    try {
      yield put({ type: 'MANAGE_USERS_SAVING' })
      const currentApp = window.location.pathname.split('/')[1]
      const userId = Cookies.get('uid')

      if (isNew) {
        delete user.id
        const newUser = yield call(
          [firebaseSecondary.auth(), firebaseSecondary.auth().createUserWithEmailAndPassword],
          user.email,
          uid()
        );
        yield call([firebaseSecondary.auth(), firebaseSecondary.auth().signOut]);
        const updatedUser = { ...user, id: newUser.user.uid, superadmin: user.superadmin ? user.superadmin : false };

        yield call(
          rsFirebase.firestore.setDocument,
          `users/${newUser.user.uid}`,
          updatedUser,
          { merge: true }
        )
        yield put({ type: 'MANAGE_USERS_ADD', user: updatedUser })
        yield put({ type: 'MANAGE_USERS_SAVE_SUCCESS' })
      } else {
        yield call(rsFirebase.firestore.setDocument, `users/${user.id}`, user, {
          merge: true,
        })

        if (userId === user.id) {
          const userProfile = {
            email: user.email,
            firstName: user.firstName,
            id: user.id,
            lastName: user.lastName,
            superadmin: user.superadmin,
            apps: user.apps,
          }
          yield put({ type: 'MANAGE_SESSION_SET_PROFILE', userProfile })
        }
        yield put({ type: 'MANAGE_USERS_ADD', user })
        yield put({ type: 'MANAGE_USERS_SAVE_SUCCESS' })

        if(!isShare) {
          yield put(push(rteManageUserId(currentApp, user.id)))
        } else {
          window.location.reload()
        }
      }
    } catch (error) {
      console.log(error)
      yield put({ type: 'MANAGE_USERS_SAVE_ERROR', error })
    }
  },
  *MANAGE_USER_UPDATE_RIGHTS({appId, user, options}) {
    try {
      let appIndex = user.apps.findIndex(app => app.appId === appId);
      let roleUser = '';

      if(user.superadmin) return;

      if (user.apps[appIndex].roles && user.apps[appIndex].roles.length > 0) {
        roleUser = user.apps[appIndex].roles[0];
      }

      if (roleUser.typeAccess === 'none_exept') {
        if (options.type === 'folders') {
          const response = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/folders/`);
          if (!response.docs) {
            return;
          }
          const folders = response.docs.map(doc => doc.data());
          const newFolder = folders.find(folder => folder.name === options.name);
          if (newFolder && appIndex !== -1) {
            let accessFolders = roleUser.access.folders || [];
            const folderExists = accessFolders.some(folder => folder.folderId === newFolder.id);
            if (!folderExists) {
              accessFolders.push({ folderId: newFolder.id });
            }
          }
          yield call(rsFirebase.firestore.setDocument, `users/${user.id}`, user, {
            merge: true,
          })
          window.location.reload()
        }
        else if(options.type === 'cards') {
          let cardsUnlinked = roleUser.access.unlinked || [];
          const cardExists = cardsUnlinked.some(card => card.cardId === options.card.cardId);
          if (!cardExists) {
            cardsUnlinked.push({cardId: options.card.cardId});
          }
          yield call(rsFirebase.firestore.setDocument, `users/${user.id}`, user, {
            merge: true,
          })
          const userProfile = yield call(getUserProfile, user.id)
          yield put({ type: 'MANAGE_SESSION_SET_PROFILE', userProfile })

          //yield put({ type: 'MANAGE_USER_UPDATE_RIGHTS_SUCCESS' });
        }
      }
    } catch (error) {
      console.log(error)
    }
  },
  *MANAGE_USER_DELETE_RIGHTS({appId, user, options}) {
    if(options.type === 'folders') {
      const cardsLinked = [];
      const cardsUnlinked = [];
      const response = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/cards/`);
      if (!response.docs) {
        return;
      }
      const cards = response.docs.map(doc => doc.data());

      for (const card of cards) {
        if (card.folders && card.folders.includes(options.id)) {
          if(card.tokenId === 0) {
            cardsUnlinked.push(card.cardId);
          } else {
            cardsLinked.push(card.cardId);
          }
          const updatedFolders = card.folders.filter(folderId => folderId !== options.id);
          yield call(updateOneCard, {...card, folders: updatedFolders}, appId)
        }
      }

      const list = yield call(getUserList, user.id, appId)
      for (const user of list) {
        const userApp = user.apps && user.apps.find(app => app.appId === appId);
        if (userApp) {
          const userRole = user.apps && userApp.roles.find(role =>
            role.access &&
            role.access.folders &&
            role.access.folders.some(folder => folder.folderId === options.id)
          );

          if (userRole) {
            userRole.access.folders = userRole.access.folders.filter(folder => folder.folderId !== options.id);
            if (cardsLinked.length > 0 && userRole.access.linked) {
              userRole.access.linked = userRole.access.linked.filter(linked => !cardsLinked.includes(linked.cardId));
            }
            if (cardsUnlinked.length > 0 && userRole.access.unlinked) {
              userRole.access.unlinked = userRole.access.unlinked.filter(unlinked => !cardsUnlinked.includes(unlinked.cardId));
            }
          }
          yield call(rsFirebase.firestore.setDocument, `users/${user.id}`, user, {
            merge: true,
          })
        }
      }
    }
    else if (options.type === 'cardsDelete' || options.type === 'cardsReset') {
      try {
        const listUsers = yield call(getUserList, user.id, appId)
        for (const userData of listUsers) {
          const userApp = userData.apps.find(app => app.appId === appId);
          if (userApp && options.cardId) {
            const userRole = userApp.roles.find(role =>
              role.typeAccess === 'none_exept' && role.role !== 'superowner' && role.role !== 'owner' && role.role !== 'admin'
            );
            if (userRole) {
              if(options.type === 'cardsDelete') {
                let cardsUnlinked = userRole.access.unlinked || [];
                const cardIndex = cardsUnlinked.findIndex(card => card.cardId === options.cardId);
                if (cardIndex !== -1) {
                  cardsUnlinked.splice(cardIndex, 1);
                }
              } else {
                let cardsLinked = userRole.access.linked || [];
                let cardsUnlinked = userRole.access.unlinked || [];
                const cardIndex = cardsLinked.findIndex(card => card.cardId === options.cardId);
                if (cardIndex !== -1) {
                  cardsLinked.splice(cardIndex, 1);
                  cardsUnlinked.push({cardId: options.cardId})
                }
              }
              yield call(rsFirebase.firestore.setDocument, `users/${userData.id}`, userData, {
                merge: true,
              })
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  *MANAGE_SESSION_LOGIN({ email, password }) {
    try {
      yield put({ type: 'MANAGE_SESSION_LOADING' })
      const user = yield call(rsFirebase.auth.signInWithEmailAndPassword, email, password)

      const userProfile = yield call(getUserProfile, user.user.uid)

      const userActiveApps = yield call(getUserActiveApps, user.user.uid)

      if (userProfile.disabled) {
        const error = { code: 'auth/user-disabled', message: 'User is disabled' }
        yield put({ type: 'MANAGE_SESSION_ERROR', error })
      } else if (userActiveApps.length === 0 && Array.isArray(userActiveApps)) {
        const error = { code: 'auth/userWithoutApps', message: 'User dont have apps assigned to his account' }
        yield put({ type: 'MANAGE_SESSION_ERROR', error })
      } else {
        Cookies.set('uid', user.user.uid)
        Cookies.set('email', email)
        Cookies.set('profile', userProfile)

        yield put({ type: 'MANAGE_SESSION_FETCH_APPS', uid: user.user.uid })

        const data = yield put({ type: 'MANAGE_SESSION_LOGIN_SUCCESS', uid: user.user.uid, email, userProfile })

        yield put({
          type: 'LOGS_ADD_LOGS_SESSION',
          action: 'login',
        });
        yield take('LOGS_ADD_LOGS_SUCCESS');
        yield put({ type: 'LOGS_ADD_LOGS_RESET_SUCCESS' })

        if (userProfile.superadmin) {
          const appId = localStorage.getItem(LAST_APP_VISITED) || 'msjdata';
          yield put(push(rteCardsTag(appId, 'linked')))
        } else {
          const lastVisitedAppId = localStorage.getItem(LAST_APP_VISITED);
          let appId;
          if (lastVisitedAppId && data.userProfile.apps.some(app => app.appId === lastVisitedAppId)) {
            appId = lastVisitedAppId;
          } else {
            appId = data.userProfile.apps[0].appId;
          }
          yield put(push(rteCardsTag(appId, 'linked')))
        }
      }
    } catch (error) {
      yield put({ type: 'MANAGE_SESSION_ERROR', error })
    }
  },
  *MANAGE_SESSION_LOGOUT() {
    try {
      yield put({
        type: 'LOGS_ADD_LOGS_SESSION',
        action: 'logout',
      });
      yield take('LOGS_ADD_LOGS_SUCCESS');
      yield put({ type: 'LOGS_ADD_LOGS_RESET_SUCCESS' })

      yield call(rsFirebase.auth.signOut)

      Cookies.set('uid', '')
      Cookies.set('email', '')
      Cookies.set('profile', {})

      yield put({ type: 'MANAGE_SESSION_LOGOUT_SUCCESS' })
      yield put({ type: 'MANAGE_SESSION_RESET_STATUS' })
      yield put(push('/login'))
    } catch (error) {
      yield put({ type: 'MANAGE_SESSION_ERROR', error })
    }
  },
  *MANAGE_SESSION_FETCH_APPS({ uid }) {
    try {
      yield put({ type: 'MANAGE_SESSION_LOADING' })
      const userProfile = yield call(getUserProfile, uid)

      let appList = []

      // if admin
      if (userProfile.superadmin) {
        const listSnap = yield call(rsFirebase.firestore.getCollection, 'appdata')
        const list = {}
        listSnap.forEach((data) => {
          list[data.id] = data.data()
        })
        appList = Object.keys(list)
      } else {
        appList = [];
        userProfile.apps.map((app) => appList.push(app.appId))
      }

      const activeApps = yield call(getUserActiveApps, uid)

      if (activeApps.length === 0) {
        const error = { code: 'auth/userWithoutApps', message: 'User dont have apps assigned to his account' }
        yield put({ type: 'MANAGE_SESSION_ERROR', error })
        yield put({ type: 'MANAGE_SESSION_LOGOUT' })
      }

      yield put({ type: 'MANAGE_SET_APPLIST', appList })
      yield put({ type: 'MANAGE_SESSION_FETCH_SUCCESS', appList })
      return appList
    } catch (error) {
      yield put({ type: 'MANAGE_SESSION_ERROR', error })
    }
  },
  *MANAGE_SESSION_SEND_EMAIL({ email }) {
    try {
      yield put({ type: 'MANAGE_SESSION_LOADING' })
      yield call(rsFirebase.auth.sendPasswordResetEmail, email)
      yield put({ type: 'MANAGE_SESSION_SUCCESS', sent: true })
    } catch (error) {
      yield put({ type: 'MANAGE_SESSION_ERROR', error })
    }
  },
  *DELETE_DISABLED_USERS({ userId, appId }) {
    try {
      yield put({ type: 'MANAGE_SESSION_LOADING' })
      const list = yield call(getUserList, userId, appId)

      const disabledUsers = list.filter((user) => user.disabled)
      const disabledUsersIds = disabledUsers.map((user) => user.id)

      yield all(disabledUsersIds.map((id) => call(rsFirebase.firestore.deleteDocument, `users/${id}`)))
	    
	    const listUpdated = yield call(getUserList, userId, appId)

      yield put({ type: 'MANAGE_USERS_SET', listUpdated })
      yield put({ type: 'MANAGE_SESSION_SUCCESS' })

	   /* yield put(push(RTE.MANAGEUSERS))*/
    } catch (error) {
      yield put({ type: 'MANAGE_SESSION_ERROR', error })
    }
  },
  *LIST_ENABLED_USERS() {
    try {
      yield put({ type: 'MANAGE_SESSION_LOADING' })
      const list = yield call(getUserList)
      const enabledUsers = list.filter((user) => !user.disabled)
      //Create list of enabled user's emails and download it in a .txt file
      const emails = enabledUsers.map((user) => user.email)
      const txt = emails.join('\n');

      const blob = new Blob([txt], { type: 'text/plain' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'enabled-users.txt'
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      yield put({ type: 'MANAGE_SESSION_ERROR', error })
    }
  },
  *MANAGE_SESSION_RESET_PASSWORD({ code, password }) {
    try {
      yield put({ type: 'MANAGE_SESSION_LOADING' })
      yield call(rsFirebase.auth.confirmPasswordReset, code, password)
      yield put({ type: 'MANAGE_SESSION_SUCCESS' })
    } catch (error) {
      yield put({ type: 'MANAGE_SESSION_ERROR', error })
    }
  },
})
