import { createSagas } from 'redux-box'
import { put, call } from 'redux-saga/effects'
import rsFirebase from 'services/rsf'
import { getAppLegalContacts } from 'store/app/workers'
import {
  getApp,
  getNewApp,
  createCards,
  getTokenCardLinks,
} from 'store/manageApps/workers'
import { LAST_APP_VISITED } from '../../utils/consts';
import uid from 'uid'
import { getUserId, getUserProfile } from "../manageUsers/workers";
import { splitFileName } from '../../services/utils'
import { uploadFile, uploadFile64 } from '../../services/fileManager'

function* handleFileFavicon(data, path) {
  const fileNameObj = splitFileName(data.favicon.name)
  const fileName = `${fileNameObj.name}-${uid('7')}.${fileNameObj.ext}`
  const pathWithName = `${path}/${fileName}`

  const fileInfos = data.base64
    ? yield uploadFile64(data.base64.slice(23), pathWithName)
    : yield uploadFile(data.favicon, pathWithName)

  delete data.favicon
  delete data.base64

  data.favicon = fileInfos.url

  return data
}


export default createSagas({
  *MANAGE_APP_SAVE_REQUEST({ app, appId }) {
    try {
      const date = Date.now()

      const dbAppSnap = yield call(rsFirebase.firestore.getDocument, `appdata/${app.id}`)
      const dbApp = dbAppSnap.data()

      if(!dbApp) {
        // Create new App
        let newApp = getNewApp(app, date)
        yield call(rsFirebase.firestore.setDocument, `appdata/${app.id}`, newApp)
        const tokensCards = createCards(newApp.tokenCardLinks, date, newApp.theme)

        // Create tokens pages
        for (const card of tokensCards) {
          yield call(rsFirebase.firestore.setDocument, `appdata/${app.id}/cards/${card.cardId}`, card);
        }

        // Create addOns collection
        if(app.addOns.length > 0) {
          for (let i = 0; i < app.addOns.length; i++) {
            const add = app.addOns[i];
            yield call(rsFirebase.firestore.setDocument, `appdata/${app.id}/addOns/${i}`, add);
          }
        }

        // Create legal contacts collection
        for (let i = 0; i < app.legalContacts.length; i++) {
          const contact = app.legalContacts[i];
          yield call(rsFirebase.firestore.setDocument, `appdata/${app.id}/legalContacts/${i}`, contact);
        }

        yield put({
          type: 'MANAGE_APP_SAVE_SUCCESS',
          app: newApp,
        })
        yield put({ type: 'MANAGE_SESSION_ADD_APP', appId: app.id })

        if (appId === app.id) {
          yield put({ type: 'APP_FETCH_BY_ID', appId })
        }

        yield put({ type: 'MANAGE_APPS_GET_APPS'})

        const userId = getUserId()
        const snapshot = yield call(rsFirebase.firestore.getDocument, `/users/${userId}/`);
        const user = snapshot.data();
        user.apps.push({
          appId: app.id,
          roles: [{
            access: {
              folders: [],
              linked: [],
              unlinked: [],
            },
            disable: false,
            endDate: "",
            role: 'superowner',
            startDate: '',
            typeAccess: 'allApp'
          }]
        });

        yield call(rsFirebase.firestore.setDocument, `users/${user.id}`, user, {
          merge: true,
        })
        const userProfile = yield call(getUserProfile, user.id)
        yield put({ type: 'MANAGE_SESSION_SET_PROFILE', userProfile })
        yield put({ type: 'MANAGE_USERLIST_GET', userId: user.id, appId: app.id  })

        return app.id
      } else {
        // Update app
        const updatedApp = getApp(dbApp, app, date)

        // Update Favicon (let this at top to avoid File object error)
        if(updatedApp.favicon && updatedApp.favicon instanceof File) yield call(handleFileFavicon, updatedApp, `/apps/${updatedApp.id}`);


        const cardList = { ...dbApp.cardList };

        // Delete tokens pages
        if(updatedApp.tokenList.length < dbApp.tokenList.length) {
          for (const card of Object.values(dbApp.tokenCardLinks)) {
            if(card.tokenId && !updatedApp.tokenList.includes(card.tokenId)) {
              const tokenCardSnap = yield call(rsFirebase.firestore.getDocument, `appdata/${app.id}/cards/${card.cardId}`)
              const tokenCard = tokenCardSnap.data()
              const id = uid()

              cardList[id] = id;

              yield call(
                rsFirebase.firestore.setDocument,
                `appdata/${app.id}/cards/${id}`,
                { ...tokenCard, cardId: id, tokenId: 0},
                { merge: true }
              )
              yield call(rsFirebase.firestore.deleteDocument, `appdata/${app.id}/cards/${card.cardId}`)
            }
          }
        }

        const tokenCardLinks = getTokenCardLinks(updatedApp.tokenList, date);

        // Add tokens pages
        if(updatedApp.tokenList.length > dbApp.tokenList.length) {
          const tokensCards = createCards(tokenCardLinks, date, updatedApp.theme)
          for (const card of tokensCards) {
            if(card.tokenId && !dbApp.tokenList.includes(card.tokenId)) {
              yield call(rsFirebase.firestore.setDocument, `appdata/${app.id}/cards/${card.cardId}`, card);
            }
          }
        }

        updatedApp.tokenCardLinks = tokenCardLinks
        updatedApp.cardList = cardList

        // Save update app
        yield call(rsFirebase.firestore.setDocument, `appdata/${app.id}`,  updatedApp)

        // Update default theme of all cards
        if (app.changeDefaultTheme) {
          const cardsSnapshot  = yield call(rsFirebase.firestore.getCollection, `appdata/${app.id}/cards`);
          const cards = cardsSnapshot.docs.map(doc => ({ ...doc.data(), cardId: doc.id }));

          for (const card of cards) {
            if (card.theme === app.oldTheme) {
              yield call(
                rsFirebase.firestore.setDocument,
                `appdata/${app.id}/cards/${card.cardId}`,
                { theme: app.theme },
                { merge: true }
              );
            }
          }
        }

        if (app.addOns.length > 0) {
          const addOnsSnapshot = yield call(rsFirebase.firestore.getCollection, `appdata/${app.id}/addOns`);
          const addOnsInDb = addOnsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

          for (let i = 0; i < app.addOns.length; i++) {
            const addOn = app.addOns[i];
            yield call(rsFirebase.firestore.setDocument, `appdata/${app.id}/addOns/${i}`, addOn, { merge: true });
          }

          for (let i = app.addOns.length; i < addOnsInDb.length; i++) {
            yield call(rsFirebase.firestore.deleteDocument, `appdata/${app.id}/addOns/${i}`);
          }
        }

        // Update legal Contacts
        const contactsSnapchot = yield call(rsFirebase.firestore.getCollection, `appdata/${app.id}/legalContacts`);
        const contacts = contactsSnapchot.docs.map(doc => ({ ...doc.data() }));
        for (let i = 0; i < contacts.length; i++) {
          yield call(rsFirebase.firestore.deleteDocument, `appdata/${app.id}/legalContacts/${i}`)
        }
        for (let i = 0; i < app.legalContacts.length; i++) {
          const contact = app.legalContacts[i];
          yield call(rsFirebase.firestore.setDocument, `appdata/${app.id}/legalContacts/${i}`, contact);
        }

        yield put({
          type: 'MANAGE_APP_SAVE_SUCCESS',
          app: updatedApp,
        })

        yield put({ type: 'MANAGE_SESSION_ADD_APP', appId: app.id })

        if (appId === app.id) {
          yield put({ type: 'APP_FETCH_BY_ID', appId })
        }

        yield put({ type: 'MANAGE_APPS_GET_APPS'})
        return app.id
      }
    } catch (error) {
      yield put({ type: 'MANAGE_APP_SAVE_FAILED', error })
    }
  },
  *MANAGE_APP_DISABLE({ app }) {
    try {
      const lastApp = localStorage.getItem(LAST_APP_VISITED);
      if(lastApp === app.id) {
        localStorage.removeItem(LAST_APP_VISITED);
      }
      yield put({ type: 'MANAGE_APP_START_FETCHING' })
      //Disable the app from firestore
      yield call(rsFirebase.firestore.updateDocument, `appdata/${app.id}`, 'disabled', !app.disabled)
      yield put({ type: 'MANAGE_APPS_GET_APPS' })
      yield put({ type: 'MANAGE_APP_STOP_FETCHING' })
    } catch (error) {
      yield put({ type: 'MANAGE_APP_SAVE_FAILED', error })
    }
  },
  *MANAGE_APPS_GET_APPS() {
    try {
      yield put({ type: 'MANAGE_APPS_GET_FETCHING' })
      // get plan and internalNotes
      const snapshot = yield call(rsFirebase.firestore.getCollection, 'appdata')
      let allApps
      let allDisabledApps = []

      snapshot.forEach((data) => {
        allApps = {
          ...allApps,
          [data.id]: { ...data.data() },
        }
        if (data.data().disabled === true) allDisabledApps.push(data.id)
      })

      const currentURL = window.location.href

      for (const appId of allDisabledApps) {
        if (currentURL.includes("/"+appId+"/")) {
          window.location.href = window.location.origin + "/logout"
          break
        }
      }

      const userId = getUserId()
      const userProfile = yield call(getUserProfile, userId)

      if((userProfile.role === 'superowner' || userProfile.role === 'owner' || userProfile.role === 'admin') && !userProfile.superadmin) {
        const allowedRoles = ['superowner', 'admin', 'owner'];
        const userApps = userProfile.apps
          .filter(app =>
            app.roles.some(role => allowedRoles.includes(role.role))
          )
          .map(app => app.appId);
        const filteredAppList = Object.keys(allApps).filter(appId => userApps.includes(appId))
        const filteredList = {}
        filteredAppList.forEach(appId => {
          filteredList[appId] = allApps[appId]
        })
        allApps = filteredList
      }

      yield put({ type: 'MANAGE_APPS_GET_SUCCESS', list: allApps })
      yield put({ type: 'MANAGE_SET_LISTDISABLEDAPPS', allDisabledApps })
      yield put({ type: 'MANAGE_APP_STOP_FETCHING' })
    } catch (error) {
      yield put({ type: 'MANAGE_APPS_GET_INFO_FAILED' })
    }
  },
  *MANAGE_APPS_GET_APP_INFO({ appId }) {
    try {
      yield put({ type: 'MANAGE_APP_START_FETCHING' })
      const obj = {}
      obj.legalContacts = yield call(getAppLegalContacts, appId)

      const docSnapshot = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}`)
      const documents = docSnapshot.data()
      if (documents) {
        if (documents.plan) {
          obj.plan = documents.plan
        }
        if (documents.internalNotes) {
          obj.internalNotes = documents.internalNotes
        }
      }

      const AoSnapshot = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/addOns`)
      const addOns = []
      AoSnapshot.forEach((addOn) => {
        addOns.push({ ...addOn.data() })
      })
      obj.addOns = addOns
      yield put({ type: 'MANAGE_APP_USER_LIST', appId })
      yield put({ type: 'MANAGE_APP_START_FETCHING' })
      yield put({ type: 'MANAGE_APP_CREATE_RESET' })
      yield put({ type: 'MANAGE_APPS_SET_APP_INFO', values: obj, appId })
      yield put({ type: 'MANAGE_APP_STOP_FETCHING' })
    } catch (error) {
      yield put({ type: 'MANAGE_APPS_GET_INFO_FAILED' })
    }
  },
  *MANAGE_APPS_GET_LEGALCONTACTS({ appId }) {
    try {
      const snapshot = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/legalContacts`)
      const legalContacts = []
      snapshot.forEach((contact) => {
        legalContacts.push({ ...contact.data(), id: contact.id })
      })

      yield put({ type: 'MANAGE_APPS_SET_LEGALCONTACTS', legalContacts, appId })
    } catch (error) {
      yield put({ type: 'MANAGE_APPS_GET_INFO_FAILED' })
    }
  },
  *MANAGE_APPS_GET_ADDONS({ appId }) {
    try {
      const snapshot = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/addOns`)
      const addOns = []
      snapshot.forEach((addon) => {
        addOns.push({ ...addon.data() })
      })

      yield put({ type: 'MANAGE_APPS_SET_ADDONS', addOns, appId })
    } catch (error) {
      yield put({ type: 'MANAGE_APPS_GET_INFO_FAILED' })
    }
  },
  *MANAGE_APP_USER_LIST({ appId }) {
    try {
      const snapshot = yield call(rsFirebase.firestore.getCollection, 'users');
      let users = [];

      snapshot.forEach((doc) => {
        const user = doc.data();
        if(!user.disabled && user.apps && user.apps.some(app => app.appId === appId)) {
          users.push(user);
        }
      });

      yield put({ type: 'MANAGE_APPS_SET_USERS_LIST', users });
    } catch (error) {
      yield put({ type: 'MANAGE_APPS_GET_INFO_FAILED' })
    }
  },
  *MANAGE_APP_DELETE_DISABLED() {
    try {
      yield put({ type: 'MANAGE_APP_START_FETCHING' })
      const snapshot = yield call(rsFirebase.firestore.getCollection, 'appdata')
      const allDisabledApps = []
      snapshot.forEach((data) => {
        if (data.data().disabled === true) allDisabledApps.push(data.id)
      })

      const toDeleteIndex = Object.keys(allDisabledApps).length
      for (let i = 0; i < toDeleteIndex; i += 1) {
        yield call(rsFirebase.firestore.deleteDocument, `appdata/${allDisabledApps[i]}`)
      }
    } catch (error) {
      yield put({ type: 'MANAGE_APPS_GET_INFO_FAILED' })
    }
  },
})
